
<template>
  <b-modal
    class="select-addons"
    v-b-modal.modal-xl
    id="modal-primary"
    ref="modal-primary"
    hide-footer
    no-close-on-backdrop
    :title="'Select Addons'"
    :size="'xl'"
    centered
    @hide="hideModal('')"
    v-if="getWhatModal == 'addons'"
  >
    <div
      v-show="Object.keys(selectedAddonModifier).length == 0"
      v-if="selectedProduct && selectedProduct.addons"
    >
      <b-container fluid>
        <b-row>
          <b-col
            v-for="addon in selectedProduct.addons"
            :key="addon.id"
            :lg="12"
            :md="8"
            :sm="12"
            class="mb-4"
          >
            <b-card class="card">
              <b-card-header
                style="
                  background-color: #f4eade;
                  border-radius: 14px;
                  border: none;
                "
              >
                <span class="addon-description">{{
                  addon.modifier && addon.modifier.description
                }}</span>
                <span class="addon-max-selection float-right badge badge-info"
                  >Max selection: {{ addon.max_selection }}</span
                >
                <span class="badge required-badge ml-1" v-show="addon.required"
                  >Required</span
                >
              </b-card-header>
              <b-container fluid>
                <b-row class="mt-3">
                  <b-col
                    v-for="product in addon.addon_products"
                    :key="product.id"
                    :class="[
                      'p-2',
                      'cursor-pointer',
                      'addon-item',
                      {
                        selected: isProductSelected(addon, product),
                      },
                    ]"
                    :lg="3"
                    :md="6"
                    :sm="12"
                    @click="addOne(addon, product)"
                  >
                    <!-- @click="toggleProductSelection(addon, product)" -->

                    <h6 class="text-center pt-1">
                      {{ product.product && product.product.name }}
                    </h6>
                    <p class="text-center">
                      ${{ product.product && product.product.sale_price }}
                    </p>
                    <QuantityButton
                      v-if="isVisibleQUantity(addon, product)"
                      :disabledBtn="isSelectQuantityDisable(addon, product)"
                      :value="getSelectedCountFor(addon, product)"
                      @decrement="minusOne(addon, product)"
                      @increment="plusOne(addon, product)"
                    />

                    <div
                      v-if="
                        product.product &&
                        product.product.stock &&
                        product.product.stock.tags &&
                        product.product.stock.tags.length > 0
                      "
                    >
                      <img
                        v-for="(tag, index) in JSON.parse(
                          product.product.stock.tags
                        )"
                        :key="index"
                        :src="tag"
                        :alt="'Tag ' + (index + 1)"
                        :class="{
                          'left-tag': index == 0,
                          'right-tag': index == 1,
                        }"
                        width="30"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <!-- :disabled="isContinueButtonDisabled" -->
      <b-button
        
        :disabled="disable"
        @click="showModal('')"
        v-b-modal.modal-primary
        class="mt-3 float-right"
        style="background-color: #0f3442"
      >
        Continue
      </b-button>
    </div>
    <!-- Addon modifier  -->
    <div v-show="Object.keys(selectedAddonModifier).length > 0">
      <b-container fluid class="">
        <b-row class="justify-content-center">
          <b-col
            v-for="addon in selectedAddonModifier.addons"
            :key="addon.id"
            :lg="12"
            :md="12"
            :sm="12"
            class="mb-4"
          >
            <b-card>
              <b-card-header
                class="description-header row"
                style="border-radius: 14px"
              >
                <div
                  class="col-lg-6 d-flex justify-content-lg-start justify-content-center px-sm-2 px-0 mb-lg-0 mb-2"
                >
                  <span class="addon-description">{{
                    addon.modifier && addon.modifier.description
                  }}</span>
                  <span
                    class="badge required-badge ml-1"
                    v-show="addon.required"
                    >Required</span
                  >
                </div>
                <div
                  class="col-lg-6 d-flex justify-content-lg-end justify-content-center"
                >
                  <span class="addon-max-selection float-right badge badge-info"
                    >Max selection: {{ addon.max_selection }}</span
                  >
                </div>
              </b-card-header>
              <b-container fluid class="d-flex justify-content-center">
                <b-row class="mt-3 w-100">
                  <b-col
                    v-for="product in addon.addon_products"
                    :key="product.id"
                    :class="[
                      'p-2',
                      'cursor-pointer',
                      'addon-item',
                      {
                        selected: isAddonSelected(addon, product),
                      },
                    ]"
                    :xl="3"
                    :sm="12"
                    @click="addOne(addon, product)"
                  >
                    <!-- @click="toggleProductSelection(addon, product)" -->

                    <h6 class="text-center">
                      {{ product.product && product.product.name }}
                    </h6>
                    <p class="text-center">
                      ${{ product.product && product.product.sale_price }}
                    </p>
                    <!-- <p class="text-center">
                      id {{ product.product && product.product.id }}
                    </p> -->
                    <QuantityButton
                    v-if="isVisibleQUantity(addon, product)"
                      :disabledBtn="isSelectQuantityDisable(addon, product)"
                      :value="getSelectedCountFor(addon, product)"
                      @decrement="minusOne(addon, product)"
                      @increment="plusOne(addon, product)"
                    />
                    <div
                      v-if="
                        product.product &&
                        product.product.stock &&
                        product.product.stock.tags &&
                        product.product.stock.tags.length > 0
                      "
                    >
                      <img
                        v-for="(tag, index) in JSON.parse(
                          product.product.stock.tags
                        )"
                        :key="index"
                        :src="tag"
                        :alt="'Tag ' + (index + 1)"
                        :class="{
                          'left-tag': index == 0,
                          'right-tag': index == 1,
                        }"
                        width="30"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <!-- :disabled="isContinueButtonDisabled" @click="clearSelectedAddons()" -->
      <b-button
        :disabled="SubAddonsDisable"
        @click="clearSelectedAddons()"
        v-b-modal.modal-primary
        class="mt-3 float-right"
        style="background-color: #0f3442"
      >
        Continue
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import mixins from "@/components/mixins/swal";
import { mapGetters, mapMutations } from "vuex";
import QuantityButton from "./QuantityButton.vue";
import lodash from 'lodash';

export default {
  name: "ModalOrder",
  props: ["whatModal", "selectedProduct"],
  mixins: [mixins],
  components: {
    QuantityButton,
  },
  data() {
    return {
      selectedAddons: [],
      completeAddonsObj: [],
      selectedAddonModifier: {},
      selected_quantity: 0,
      disable: true,
      SubAddonsDisable: true,
    };
  },
  computed: {
    ...mapGetters("modal", ["getWhatModal"]),

    isContinueButtonDisabled() {
      // console.log('buttonDis',this.selectedProduct.addons);
      for (const addon of this.selectedProduct.addons) {
        if (addon.required) {
          const selectedProductsInRow = this.selectedAddons.filter(
            (selectedProduct) => selectedProduct.addon_id === addon.id
          );

          if (selectedProductsInRow.length === 0) {
            return true;
          }
        }     
      }
      for (const product of this.completeAddonsObj) {
        if (
          product.product &&
          product.product.stock &&
          (!product.product.stock.is_enabled ||
            product.product.stock.available_stock <= 0)
        ) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    ...mapMutations("category", ["SET_PRODUCT_ADDONS"]),
    ...mapMutations("modal", ["SET_WHAT_MODAL", "HIDE_MODAL"]),
    ...mapMutations("cart", ["ADD_TO_CART"]),

    hideModal(val) {
      this.SET_WHAT_MODAL(val);
      this.selectedAddons = [];
      this.completeAddonsObj = [];
    },

    showModal(val) {
      this.SET_WHAT_MODAL(val);
      this.submitProduct();
    },

    clearSelectedAddons() {
      this.selectedAddonModifier = {};
    },

    submitProduct() {
      this.ADD_TO_CART({
        product: {
          id: this.selectedProduct.id,
          name: this.selectedProduct.name,
          sale_price: this.selectedProduct.sale_price,
          preview_url: this.selectedProduct.preview_url,
        },
        addons_id: lodash.cloneDeep(this.selectedAddons),
        addons: lodash.cloneDeep(this.completeAddonsObj),

        qty: 1,
        productWithAddons: lodash.cloneDeep(this.selectedAddons),
      });
      this.selectedAddons = [];
      this.completeAddonsObj = [];
      this.SET_PRODUCT_ADDONS(null);
    },

    toggleProductSelection(addon, product) {
     
      const isAlreadySelected = this.isProductSelected(addon, product);
      const isRequiredAndSingleSelection =
        addon.required && addon.max_selection === 1;
      const isNotRequiredAndSingleSelection =
        !addon.required && addon.max_selection === 1;
      const isRequiredAndMultipleSelection =
        addon.required && addon.max_selection > 1;

      if (isRequiredAndSingleSelection) {
        // If addon product is required and only one selection is allowed,
        // switch to the new product if already selected changed
        if (!isAlreadySelected) {
          this.switchSelection(addon, product);
        }
      } else if (isNotRequiredAndSingleSelection) {
        // If addon product is not required and only one selection is allowed,
        // switch to the new product if already selected changed
        if (this.getSelectedCount(addon) > 1 || !isAlreadySelected) {
          this.switchSelection(addon, product);
        } else {
          style = "background-color: #F4EADE;";
          this.unselectProduct(addon, product);
        }
      } else if (isRequiredAndMultipleSelection) {
        // If addon requires multiple selections and there's only one selection,
        // do nothing to maintain at least one selection
        if (
          (this.getSelectedCount(addon) < addon.max_selection ||
            isAlreadySelected) &&
          (this.getSelectedCount(addon) > 1 || !isAlreadySelected)
        ) {
          this.toggleSelection(addon, product);
        }
      } else {
        // For other cases, toggle the selection based on the current state
        this.toggleSelection(addon, product);
      }
      if (
        product.selected &&
        product.product.addons &&
        product.product.addons.length > 0
      ) {
        this.selectedAddonModifier = product.product;
      }
    },

    toggleSelection(addon, product) {
      if (this.isProductSelected(addon, product)) {
        this.unselectProduct(addon, product);
      } else {
        this.selectProduct(addon, product);
      }
    },


    isProductSelected(addon, product) {
      return this.selectedAddons.some(
        (selectedProduct) =>
          selectedProduct.addon_id === addon.id &&
          selectedProduct.addon_product_id === product.product.id
      );
    },
    selectProduct(addon, product) {
      let exists = this.selectedAddons.find(
        (obj) => obj.addon_product_id === product.product.id
      );
      if (exists) {
        exists.selected_quantity = exists.selected_quantity + 1;
      } else {
        this.selectedAddons.push({
          addon_id: addon.id,
          addon_product_id: product.product.id,
          selected_quantity: 1,
        });
      }
      this.completeAddonsObj.push(product);
      product.selected = true;
    },

    unselectProduct(addon, product) {
      const addonProductIdToRemove = product.product.id;

      this.selectedAddons = this.selectedAddons.filter(
        (selectedProduct) =>
          !(
            selectedProduct.addon_id === addon.id &&
            selectedProduct.addon_product_id === addonProductIdToRemove
          )
      );

      this.completeAddonsObj = this.completeAddonsObj.filter(
        (completeProduct) =>
          !(
            completeProduct.product_modifier_id === addon.id &&
            completeProduct.product_id === addonProductIdToRemove
          )
      );

      if (product.product.addons && product.product.addons.length > 0) {
        for (let i = 0; i < product.product.addons.length; i++) {
          for (
            let j = 0;
            j < product.product.addons[i].addon_products.length;
            j++
          ) {
            this.unselectProduct(
              product.product.addons[i],
              product.product.addons[i].addon_products[j]
            );
          }
        }
      }

      product.selected = false;
    },

    minusOne(addon, product) {
      if (this.getTotalSelectedCount(addon, product) > 0) {
        this.unselectProductFor(addon, product);
      }
      this.isContinue(addon, product);
    },

    
    plusOne(addon, product) {
      if(addon.max_selection == 0) {
        this.selectProductFor(addon, product);
        if (product.product.addons) {
          if (product.product.addons.length) {
            this.selectedAddonModifier = product.product;
            this.SubAddonsDisable = true;
          }
        }
      }else {
        if (this.getTotalSelectedCount(addon, product) < addon.max_selection) {
          this.selectProductFor(addon, product);
        }
      }
      
        this.isContinue(addon, product);
      
    },

    isAddonSelected(addon, product) {
      return this.getSelectedCountFor(addon, product) > 0 ? true : false;
    },

    isContinue(addon, product)
    {

      let total_required = this.selectedProduct.addons
        .filter((selected_add) => selected_add.required === 1);
        
      let check_disable = 0;
      if(total_required.length  > 0)
      {
        total_required.forEach(req_item => {
          if(req_item.min_selection > this.getTotalSelectedCount(req_item, product))
          {
            check_disable = 1;
          }
        })
        this.disable = check_disable == 1  ? true : false;
      } else {
        this.disable = this.selectedAddons.length > 0 ? false : true;
      }


    },

    isSelectQuantityDisable(addon, product) {
      if (addon.max_selection != 0) {
        let status =
          this.getTotalSelectedCount(addon, product) < addon.max_selection
            ? false
            : true;
        status = this.getSelectedCountFor(addon, product) > 0 ? false : status;
        return status;
      }

      return false;
    },

    getTotalSelectedCount(addon, product) {

      if(product.product.addons){
        let totalQuantity = this.selectedAddons
        .filter((selectedProduct) => selectedProduct.addon_id === addon.id)
        .reduce(
          (sum, selectedProduct) => sum + selectedProduct.selected_quantity,
          0
        );
        return totalQuantity;

      } else {
        let all_have_sub_addons = this.selectedAddons.find(
            (selectedProduct) => selectedProduct.selected_sub_addon_id
            == addon.id
          );

        if(all_have_sub_addons)
        {
          const totalQuantity = all_have_sub_addons.selected_sub_addons_qty > 0 ? all_have_sub_addons.selected_sub_addons_qty : 0;
          return totalQuantity;
        } else {
          return 0;
        }
      }

      return 0;
    },

    isVisibleQUantity(addon, product) {
      let visible = this.getSelectedCountFor(addon, product) > 0 ? true : false;

      return visible;
    },

    addOne(addon, product) {
      if (this.getSelectedCountFor(addon, product) == 0) {
        this.plusOne(addon, product);
      }
    },

    getSelectedCountFor(addon, product) {
      if(product.product.addons){
          const addons_arr = this.selectedAddons.filter(
            (selectedProduct) => selectedProduct.addon_id === addon.id
          );
        let exists_addon = addons_arr.find(
          (obj) => obj.addon_product_id === product.product_id
        );
        let selected_quantity = exists_addon ? exists_addon.selected_quantity : 0;
        return selected_quantity;
      } else {

        let all_have_sub_addons = this.selectedAddons.find(
            (selectedProduct) => selectedProduct.selected_sub_addon_id
            == addon.id
          );

        if(all_have_sub_addons)
        {
          const item_is = all_have_sub_addons.selected_addons.find(
            (sub_addon) => sub_addon.addon_product_id == product.product_id);
            if(item_is){
              return item_is.selected_quantity > 0 ?  item_is.selected_quantity : 0 ;
            }
            return 0;
        } else {
          return 0;
        }
        
        
      }
      
    },

    selectProductFor(addon, product) {
      let exists = this.selectedAddons.find(
        (obj) => obj.addon_product_id === product.product.id
      );
      if (exists) {
        exists.selected_quantity = exists.selected_quantity + 1;
      } else {
        // console.log('cliclked');
        if(product.product.addons){
          this.selectedAddons.push({
          addon_id: addon.id,
          addon_product_id: product.product_id,
          selected_quantity: 1,
          selected_addons : [],
          selected_sub_addons_qty: 0,
          selected_sub_addon_id: '',
          addon_required: addon.required,});
        } else {
          let exist_sub_addon = this.selectedAddons[this.selectedAddons.length - 1].selected_addons.find((obj) => obj.addon_product_id
          === product.product.id);
          if(exist_sub_addon) {
            // console.log('clicked');
            exist_sub_addon.selected_quantity = exist_sub_addon.selected_quantity + 1;
            this.selectedAddons[this.selectedAddons.length - 1].selected_sub_addons_qty = this.selectedAddons[this.selectedAddons.length - 1].selected_sub_addons_qty + 1;
            // console.log('qty',exist_sub_addon.selected_quantity);
          } else {
            this.selectedAddons[this.selectedAddons.length - 1].selected_addons.push({
            addon_id: addon.id,
            addon_product_id: product.product_id,
            addon_required: addon.required,
            selected_addons : [],
            selected_quantity: 1,
            selected_sub_addons_qty: 0,
          });
            this.selectedAddons[this.selectedAddons.length - 1].selected_sub_addons_qty = this.selectedAddons[this.selectedAddons.length - 1].selected_sub_addons_qty + 1;
            this.selectedAddons[this.selectedAddons.length - 1].selected_sub_addon_id = addon.id;
            
          }
        }
      }


      // select product completeAddonsObj 

      
      let exist_complete = this.completeAddonsObj.find(
        (obj) => obj.product_id === product.product.id
      );
      if (exist_complete) {
        exist_complete.quantity_selected = exist_complete.quantity_selected + 1;
       
      } else {
        product.quantity_selected = product.quantity_selected ? product.quantity_selected : 1;
        if(product.product.addons)
        {
          product.addon_required = addon.required;
          product.product.selected_addons = [];
          this.completeAddonsObj.push(product);
        } else {
          // this.SubAddonsDisable = false;
          let exist_selected_addon = this.completeAddonsObj[this.completeAddonsObj.length - 1].product.selected_addons.find((obj) => obj.product_id === product.product.id);
          if(exist_selected_addon)
          {
            // console.log('complete qty',exist_selected_addon.quantity_selected, exist_selected_addon);
            exist_selected_addon.quantity_selected = exist_selected_addon.quantity_selected + 1;
          } else {
            this.completeAddonsObj[this.completeAddonsObj.length - 1].product.selected_addons.push(product);
          }
          this.SubAddonsDisable = this.selectedAddonModifier.addons[0].min_selection <= this.getTotalSelectedCount(addon, product) ? false : true;

          // this.completeAddonsObj.push(product);
        }
      }
      product.selected = true;
      // console.log("complete plus selected,comnplete", this.selectedAddons,this.completeAddonsObj);
   
    },

    unselectProductFor(addon, product) {
      let exists = this.selectedAddons.find((obj) => obj.addon_product_id === product.product.id);
      if (exists) {
        if (exists.selected_quantity > 0) {
          exists.selected_quantity = exists.selected_quantity - 1;
          
          if (exists.selected_quantity == 0) {
            if((!product.product.addons) && this.getTotalSelectedCount(addon,product) == 0)
            {
              this.SubAddonsDisable = true;
            }
            let index_is = this.selectedAddons.findIndex(
              (obj) => obj.addon_product_id === product.product.id
            );
            if (index_is !== -1) {
              // console.log('selectedAddons',this.selectedAddons[index_is]);
              // this.selectedAddons[index_is].selected_addons[0].selected_quantity = 0;
              this.selectedAddons.splice(index_is, 1);
            }
          }
        }
      } else {
        let all_have_sub_addons = this.selectedAddons.find(
            (selectedProduct) => selectedProduct.selected_sub_addon_id
            == addon.id
          );
          if(all_have_sub_addons){
          all_have_sub_addons.selected_sub_addons_qty = all_have_sub_addons.selected_sub_addons_qty - 1;
          const item = all_have_sub_addons.selected_addons.find(
            (selectedItem) => selectedItem.addon_product_id
            == product.product.id
          ); 
          if(item.selected_quantity > 1){
            item.selected_quantity = item.selected_quantity -1;
          } else {
            let selecter_index = all_have_sub_addons.selected_addons.findIndex(
              (selectedItem) => selectedItem.addon_product_id
              == product.product.id
            );
            
            if (selecter_index !== -1) {
              
              all_have_sub_addons.selected_addons.splice(selecter_index, 1);
            }
            this.SubAddonsDisable = this.selectedAddonModifier.addons[0].min_selection <= this.getTotalSelectedCount(addon, product) ? false : true;
          }
        }
      }



      
      if(product.product.addons){
        let exist_complete = this.completeAddonsObj.find(
            (obj) => obj.product_id === product.product.id
          );
          if (exist_complete.quantity_selected > 0) {
            exist_complete.quantity_selected =
              exist_complete.quantity_selected - 1;

          }
          if (exist_complete.quantity_selected == 0) {
            let index = this.completeAddonsObj.findIndex(
              (obj) => obj.product_id === product.product.id
            );
            if (index !== -1) {
              if(this.completeAddonsObj[index].product.addons.length > 0)
              {
                let item_for_update = this.completeAddonsObj[index].product.addons[0].addon_products.filter((update_item) => update_item.quantity_selected > 0)
                item_for_update.forEach(this_item => {
                  this_item.quantity_selected = 0;
                  this_item.selected = false;

                })
              }
             
              this.completeAddonsObj.splice(index, 1);
              // this.SubAddonsDisable = this.selectedAddonModifier.addons[0].min_selection <= this.getTotalSelectedCount(addon, product) ? false : true;
            }
          }
      } else {
        let selected_addon_index = this.completeAddonsObj[this.completeAddonsObj.length - 1].product.selected_addons.findIndex((obj) => obj.product_id === product.product.id);
       
       
        if(this.completeAddonsObj[this.completeAddonsObj.length - 1].product.selected_addons[selected_addon_index].quantity_selected > 1){
          this.completeAddonsObj[this.completeAddonsObj.length - 1].product.selected_addons[selected_addon_index].quantity_selected = this.completeAddonsObj[this.completeAddonsObj.length - 1].product.selected_addons[selected_addon_index].quantity_selected -1;
          this.SubAddonsDisable = this.selectedAddonModifier.addons[0].min_selection <= this.getTotalSelectedCount(addon, product) ? false : true;
        } else {
          this.completeAddonsObj[this.completeAddonsObj.length - 1].product.selected_addons.splice(selected_addon_index, 1);
        }
      }
      
      // console.log("selected and complete min",this.selectedAddons, this.completeAddonsObj);
    },

    isProductDisabled(addon, product) {
      const selectedCount = this.selectedAddons.filter(
        (selectedProduct) => selectedProduct.addon_id === addon.id
      ).length;
      return (
        selectedCount >= addon.max_selection &&
        !this.isProductSelected(addon, product)
      );
    },
  },
};
</script>

<style scoped>
.select-addons {
  border: none;
}
.btn-lg {
  font-size: 1.5rem;
  width: 100%;
  margin: 0, 10;
}

.form-control-lg {
  font-size: 1.5rem;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.mb-2 {
  margin-bottom: 10px;
}

.addon-description {
  font-weight: bold;
}
.description-header {
  background-color: #f4eade;
  border: none;
}
.addon-max-selection {
  font-size: 0.8em;
  background-color: rgb(245, 133, 113);
  padding: 7px;
}

.addon-item {
  transition: box-shadow 0.3s ease;
  background-color: #f4eade;
  color: #000;
  border-radius: 14px;
  margin: 6px;
}

.addon-item:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.selected {
  background-color: rgb(245, 133, 113);
  color: #000;
}

.disabled {
  background-color: #bfbcbc;
  pointer-events: none;
}

.cursor {
  cursor: pointer;
}

.left-tag {
  width: 25px;
  height: 25px;
  border-radius: 0;
  margin-left: 0px !important;
  margin-top: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.right-tag {
  width: 25px;
  height: 25px;
  border-radius: 0;
  margin-left: 0px !important;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.required-badge {
  background-color: #60d68b;
  padding: 6px;
  border-radius: 7px;
  font-size: 13px;
  color: white;
}
.card {
  border: 2px solid #bebebe;
  border-radius: 20px;
}
h6 {
  font-weight: 500;
}
@media screen and (max-width: 768px) {
}
</style>

