/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable space-before-function-paren */
import Vue from 'vue'
import Order from '@/apis/Order'
import Api from '@/apis/Api'
import router from '@/router'
import message from './helper/message'
import user from './user'
import { forEach } from 'lodash'

// State
const state = {
  carts: [],
  cartCheckout: {
    products: [],
    totalPrice: 0,
  },
  emailMember: '',
  invoice: '',
  orderPayload: {
    customer_id: 0,
    type: 'dine-in',
    payment_type: 'cash',
    note: 'null',
    platform: 'DESK',
    discount: 0,
    discount_type: 'flat',
    deal: '',
    wallet: 0,
    cashback: false,
    products: [
      {
        id: null,
        quantity: 1,
        addons: [],
      },
    ],
  },
  amountToPay: 0,
}

// Getters
const getters = {
  countTotalCart: (state) => {
    return state.carts.length
  },

  getInvoice: (state) => {
    return state.invoice
  },

  cartTotalPrice: (state) => {
    let total = 0

    state.carts.forEach((cart) => {
      let productTotal = cart.product.sale_price * cart.qty
      // let productTotal = cart.product.sale_price
      // console.log('cart_addons',cart.addons);
      if (cart.addons && cart.addons.length > 0) {
        cart.addons.forEach((addon) => {
          productTotal += addon.product.sale_price * addon.quantity_selected * cart.qty
          if(addon.product.selected_addons && addon.product.selected_addons.length > 0){
            addon.product.selected_addons.forEach((sub_addon) => {
              productTotal += ((sub_addon.product.sale_price * sub_addon.quantity_selected) * addon.quantity_selected) * cart.qty;
            })
          }
        })
      }

      total += productTotal
      cart.product.price = productTotal.toFixed(2)
    })

    return total.toFixed(2)
  },

  getAllCarts: (state) => state.carts,

  getOrderPayload: (state) => {
    return state.orderPayload
  },

  getOrderType: (state) => {
    return state.orderPayload.type
  },
  getPaymentType: (state) => {
    return state.orderPayload.payment_type
  },
  getDiscountAmount: (state) => {
    return state.orderPayload.discount
  },

  getDiscountType: (state) => {
    return state.orderPayload.discount_type
  },

  getAmountToPay: (state) => {
    return state.amountToPay
  },
}

// Actions
const actions = {
  getCarts({ commit }, carts) {
    commit('SET_CARTS', carts)
  },

  clearCartsWhenLogout({ commit }) {
    commit('CLEAR_CARTS_WHEN_LOGOUT')
  },

  async createOrder({ commit, dispatch }, data) {
    // dispatch('changeIsLoading', true, { root: true })
    try {
      if (data.payload.discount == 0) {
        data.payload.discount_type = ''
      }
      const response = await Order.placeOrder(data)

      if (response.data.data.status && data.payload.payment_type == 'fiserv') {
        const dataToSend = {
          configuration: data.config,
          order: response.data.data,
          token: localStorage.getItem('token')
        }
        const message = JSON.stringify(dataToSend)
        if (
          window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage
        ) {
          window.ReactNativeWebView.postMessage(message)
        } else {
          alert('window.ReactNativeWebView.postMessage is not available')
        }
      }

      if (data.payload.payment_type != 'fiserv' && response.data.data.status) {
        message.toastSuccess('Order place successfully!')
      } else {
        message.toastError(response.data.data.message)
      }

      if (response.data.data.status) {
        commit('CLEAR_CART')
      }

      // dispatch('changeIsLoading', false, { root: true })
      return response
    } catch (error) {
      message.toastError('Facing issue while placing order!')
      // dispatch('changeIsLoading', false, { root: true })
      throw error.response
    }
  },

  orderType({ commit }, type) {
    commit('SET_ORDER_TYPE', type)
  },

  paymentType({ commit }, type) {
    commit('SET_PAYMENT_TYPE', type)
  },

  discountType({ commit }, type) {
    commit('SET_DISCOUNT_TYPE', type)
  },

  orderDeal({ commit }, type) {
    commit('SET_ORDER_DEAL', type)
  },

  discountAmount({ commit, dispatch }, amount) {
    commit('SET_DISCOUNT_AMOUNT', amount)
  },

  amountToPay({ commit, dispatch }, total) {
    commit('SET_AMOUNT_TO_PAY', total)
  },

  addOrderNote({ commit }, note) {
    commit('SET_ORDER_NOTE', note)
  },
}

function addonsArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
   
    if (arr1[i].id !== arr2[i].id || arr1[i].quantity_selected !== arr2[i].quantity_selected) {
      return false
    }
    if(arr1[i].product.selected_addons.length ===  arr2[i].product.selected_addons.length) {
      for(let k = 0; k < arr1[i].product.selected_addons.length; k++) {
        if( arr1[i].product.selected_addons[k].product_id !== arr2[i].product.selected_addons[k].product_id || arr1[i].product.selected_addons[k].quantity_selected  !==  arr2[i].product.selected_addons[k].quantity_selected) {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  return true
}

// Mutations
const mutations = {
  SET_CARTS: (state, carts) => {
    state.carts = carts
  },

  GENERATE_INVOICE: (state) => {
    const d = new Date()
    const day = d.getDate().toString()
    const month = (d.getMonth() + 1).toString()
    const year = d.getFullYear().toString().split('').splice(2, 3).join('')
    const rnd = Math.random(0, 100).toString().substr(14).toString()
    const invoice = day + month + year + rnd
    state.invoice = invoice
  },

  // ADD_TO_CART: (
  //   state,
  //   { product, addons, qty, addons_id, productWithAddons }
  // ) => {
  //   const productInCartIndex = state.carts.findIndex((item) => {
  //     return (
  //       item.product.id === product.id && addonsArraysEqual(item.addons, addons)
  //     )
  //   })
  //   console.log('state cart',state.carts);
  //   if (productInCartIndex !== -1) {
  //     console.log('array Equal ',product);
  //     state.carts[productInCartIndex].qty += qty
  //   } else {
  //     console.log('Not equal array');
  //     if(product.name == "Lunch Box"){
  //       console.log('hello lnch');
  //       const new_item = {
  //         addon_required : 1,
  //         quantity_selected : 1,
  //         product : {addons : [],selected_addons : [],sale_price : product.sale_price,name: product.name,},
          
  //         id : product.id
  //         // sale_price : product.sale_price,

  //       }
  //       addons.forEach(addon_item => {
  //         if(addon_item .product_modifier_id == 236 || addon_item .product_modifier_id == 237)
  //         {
           
  //           // console.log('new_item',new_item);
  //           console.log('yes match',addon_item );
  //           new_item.quantity = qty;
            
  //           new_item.product.addons.push(
  //             addon_item
  //           )
  //           new_item.product.selected_addons.push(
  //             addon_item
  //           )
           
  //         }
  //       })
  //       addons.push(
  //         new_item
  //       )
  //       state.carts.unshift({
  //         product,
  //         addons,
  //         qty,
  //         addons_id,
  //         productWithAddons,
  //       })
  //       console.log('After unshift',state.carts);

  //     } else{
  //       state.carts.unshift({
  //         product,
  //         addons,
  //         qty,
  //         addons_id,
  //         productWithAddons,
  //       })
  //       console.log('After unshift',state.carts);
  //     }
      

  //     // message.toastSuccess('Product added to cart');
  //   }
  // },


  ADD_TO_CART: (
    state,
    { product, addons, qty, addons_id, productWithAddons }
  ) => {
    const productInCartIndex = state.carts.findIndex((item) => {
      return (
        item.product.id === product.id && addonsArraysEqual(item.addons, addons)
      )
    })
    // console.log('item',product, addons, qty)
    if (productInCartIndex !== -1) {
      state.carts[productInCartIndex].qty += qty
    } else {
      // this condition name can be changeable while the main product for lunch box name change 

      
      console.log('product ',product.name)
      if(product.name == "Quarter Colombian Chicken Box" || product.name == "Boneless Chicken Leg" || product.name == "Stuffed Chicken Pitas Box"){
        const new_item = {
          addon_required : 1,
          quantity_selected : 1,
          product : {addons : [],selected_addons : [],sale_price : product.sale_price,name: product.name,},
          
          id : product.id
        }
       let cart_index = 0;
        let check_add = 0;
        let splice_array = [];
        for (let i = 0; i < addons.length; i++) {
          const addon_item = addons[i];
          check_add = i == 0 ? 0 : check_add;
        // this ids according to items 
        // console.log('modi_id',addon_item.product_modifier_id);
          if (addon_item.product_modifier_id === 443 || addon_item.product_modifier_id === 444 || addon_item.product_modifier_id === 439 || addon_item.product_modifier_id === 438 || addon_item.product_modifier_id === 433 || addon_item.product_modifier_id === 434) {
            // Add the item to the carts with addons
            if(check_add == 0){
              cart_index = 1;
              state.carts.unshift({
                product,
                addons,
                qty,
                addons_id,
                productWithAddons,
              });
              check_add = 1;
            }
            // console.log('After unshift', state.carts);
          } else {
            // Add the item to the carts without addons
            const media_url = addon_item.product.media[0].url != null ? addon_item.product.media[0].url : '';
            const url = addon_item.product.preview_url == null ? media_url :  addon_item.product.preview_url;

            const ready_addons = [];
            if(addon_item.product.addons.length > 0){
              const selected_addon_arr = addon_item.product.addons[0].addon_products.filter((addn_item) => addn_item.selected == true );
              selected_addon_arr.forEach((selected_item) => {
                  selected_item.product.addons = [];
                  selected_item.product.selected_addons = [];
                   const new_addon_item = {
                    addon_required : addon_item.product.addons[0].required,
                    id : addon_item.product.addons[0].id,
                    product: selected_item.product,
                    product_id : selected_item.product.id,
                    quantity_selected : selected_item.quantity_selected,
                    selected : true,
                  }
                  ready_addons.push(
                    new_addon_item
                  )
              });
             
            }
            cart_index  = cart_index > 0 ? cart_index = cart_index + 1 : cart_index;
            state.carts.unshift({
              product: {
                id: addon_item.product.id,
                name: addon_item.product.name,
                preview_url: url,
                price: addon_item.product.sale_price.toString(), // Convert sale_price to string
                sale_price: addon_item.product.sale_price,
              },
              addons: ready_addons,
              qty: addon_item.quantity_selected,
              addons_id: [],
              productWithAddons,
            });
            // addons.splice(i,0);
            splice_array.push(i);
          }
        }
        
        // splice_array.forEach(index => {
        //   state.carts[cart_index-1].addons.splice(index, 1);
        //   console.log('removable index ',state.carts[cart_index-1].addons);
        //   console.log('removable index ',state.carts[cart_index-1].addons[index]);
        // });
        splice_array.sort((a, b) => b - a).forEach(index => {
            state.carts[cart_index - 1].addons.splice(index, 1);
        });
   
        

        

      } else{
        state.carts.unshift({
          product,
          addons,
          qty,
          addons_id,
          productWithAddons,
        })
      }
      

      // message.toastSuccess('Product added to cart');
    }
  },
  REMOVE_PRODUCT_FROM_CART: (state, id) => {
    state.carts = state.carts.filter((cart) => cart.product.id !== id)
    // message.toastSuccess('Product remove from cart')
  },

  SET_EMAIL_MEMBER: (state, payload) => {
    state.emailMember = payload
  },

  CLEAR_CART: (state) => {
    message.toastSuccess('Cart has been cleaned')
    state.carts = []
    state.orderPayload.deal = ''
    state.orderPayload.discount_type = 'flat'
    state.orderPayload.discount = 0
    localStorage.removeItem('customer_token')
  },

  CLEAR_CARTS_WHEN_LOGOUT: (state) => {
    state.carts = []
    state.orderPayload.deal = ''
    state.orderPayload.discount_type = 'flat'
    state.orderPayload.discount = 0
    localStorage.removeItem('customer_token')
  },

  // for simple flow 

  // SAVE_CART_TO_MODAL: (state, { products, price }) => {
  //   console.log('save_to_cart',state,products,price);
  //   state.cartCheckout.products = products
  //   state.cartCheckout.totalPrice = price
  //   state.orderPayload.products = [];
  //   console.log('cart_state 2',state.carts);
  //   console.log('products list ',products);

  //   // state.orderPayload.products = state.cartCheckout.products.map((product) => {
  //   //   if (product.addons.length === 0) {
  //   //     return {
  //   //       // id: product.product.id,
  //   //       // quantity: product.qty,
  //   //       // addons: [],
  //   //       product: product.addons
  //   //     }
  //   //   } else {
  //   //     return {
  //   //       // id: product.product.id,
  //   //       // quantity: product.qty,
  //   //       // addons: product.addons_id.map((addon) => addon.addon_product_id),
  //   //       product: product.addons
  //   //     }
  //   //   }
  //   // })
  //   products.forEach(cart => {
  //     if(cart.addons.length > 0)
  //     {
  //       console.log('this product ', cart.product.name);
  //       cart.addons.map((product) => {
  //         console.log('inner product', product);
  //         // let item = product.product;
  //         product.product.quantity = product.quantity_selected * cart.qty;
  //         // product.product.quantity = product.quantity_selected;
  //         let  new_selected_addons = [];
  //         let new_addons = [];
  //         product.product.selected_addons.forEach(element => {
  //           element.product.quantity = element.quantity_selected;
  //           new_selected_addons.push(
  //             element.product
  //           );
  //           new_addons.push(
  //             element.product.id
  //           )
  //         });
  //         product.product.selected_addons = new_selected_addons;
  //         product.product.addons = new_addons;
  //         state.orderPayload.products.push(
  //             // {
  //             //   product : product.product,
  //             //   quantity: product.quantity_selected
  //             // }
  //             product.product
  //         )
  //       })
  //     } else {
  //       cart.product.addons = [];
  //       cart.product.quantity = cart.qty;
  //       state.orderPayload.products.push(
  //         cart.product
  //     )
  //     }
  //   })
  //     console.log('pay',state.orderPayload.products);
  // },






  // for lunch box in kds 
  
  
  SAVE_CART_TO_MODAL: (state, { products, price }) => {
    state.cartCheckout.products = products
    state.cartCheckout.totalPrice = price
    state.orderPayload.products = [];
   

    // state.orderPayload.products = state.cartCheckout.products.map((product) => {
    //   if (product.addons.length === 0) {
    //     return {
    //       // id: product.product.id,
    //       // quantity: product.qty,
    //       // addons: [],
    //       product: product.addons
    //     }
    //   } else {
    //     return {
    //       // id: product.product.id,
    //       // quantity: product.qty,
    //       // addons: product.addons_id.map((addon) => addon.addon_product_id),
    //       product: product.addons
    //     }
    //   }
    // })
    products.forEach(cart => {
      if(cart.addons.length > 0)
      {
        console.log('cart.product.name',cart.product.name)
        if(cart.product.name == 'Quarter Colombian Chicken' || cart.product.name == 'Boneless Chicken Leg' || cart.product.name == "Quarter Colombian Chicken Box" || cart.product.name == "Stuffed Chicken Pitas Box")
        {
          cart.product.quantity = cart.qty;
          cart.product.addons = [];

          cart.addons.map((product) => {
            // product.product.quantity = product.quantity_selected;
            let  new_selected_addons = [];
            let new_addons = [];
            product.product.quantity = product.quantity_selected * cart.qty;
            product.product.selected_addons = [];
            product.product.addons = [];
            cart.product.selected_addons = [];
            cart.product.addons.push(
              product.product.id
            )
            cart.product.selected_addons.push(
              product.product
            )

            
            // product.product.selected_addons.forEach(element => {
            //   element.product.quantity = element.quantity_selected;
            //   new_selected_addons.push(
            //     element.product
            //   );
            //   new_addons.push(
            //     element.product.id
            //   )
            // });
            // product.product.selected_addons = new_selected_addons;
            // product.product.addons = new_addons;
          })
          state.orderPayload.products.push(
            cart.product
          )

        }else{
          cart.addons.map((product) => {
            
              
            // let item = product.product;
            product.product.quantity = product.quantity_selected * cart.qty;
            // product.product.quantity = product.quantity_selected;
            let  new_selected_addons = [];
            let new_addons = [];
            product.product.selected_addons.forEach(element => {
              element.product.quantity = element.quantity_selected;
              new_selected_addons.push(
                element.product
              );
              new_addons.push(
                element.product.id
              )
            });
            product.product.selected_addons = new_selected_addons;
            product.product.addons = new_addons;
            state.orderPayload.products.push(
                // {  
                //   product : product.product,
                //   quantity: product.quantity_selected
                // }
                product.product
            )
          })
        }
        
      } else {
        cart.product.addons = [];
        cart.product.quantity = cart.qty;
        state.orderPayload.products.push(
          cart.product
      )
      }
    })
  },

  MIN_QTY_CART: (state, { product, addons }) => {
    const productInCartIndex = state.carts.findIndex((item) => {
      return (
        item.product.id === product.id && addonsArraysEqual(item.addons, addons)
      )
    })

    if (productInCartIndex !== -1) {
      if (state.carts[productInCartIndex].qty > 1) {
        state.carts[productInCartIndex].qty--
      }
    } else {
      state.carts.unshift({ product, addons, qty })
      message.toastSuccess('Product quantity updated!')
    }
  },

  PLUS_QTY_CART: (state, { product, addons }) => {
    const productInCartIndex = state.carts.findIndex((item) => {
      return (
        item.product.id === product.id && addonsArraysEqual(item.addons, addons)
      )
    })

    if (productInCartIndex !== -1) {
      state.carts[productInCartIndex].qty++
    } else {
      state.carts.unshift({ product, addons, qty })
      message.toastSuccess('Product quantity updated!')
    }
  },

  USE_CASHBACK: (state, { product, addons }) => {
    const productInCartIndex = state.carts.findIndex((item) => {
      return (
        item.product.id === product.id && addonsArraysEqual(item.addons, addons)
      )
    })

    if (productInCartIndex !== -1) {
      state.carts[productInCartIndex].qty++
    } else {
      state.carts.unshift({ product, addons, qty })
      message.toastSuccess('Product quantity updated!')
    }
  },

  CHANGE_TOTAL_QTY: (state, { product, qty, addons }) => {
    const productInCartIndex = state.carts.findIndex((item) => {
      return (
        item.product.id === product.id && addonsArraysEqual(item.addons, addons)
      )
    })

    if (productInCartIndex !== -1) {
      if (state.carts[productInCartIndex].qty < 1) {
        state.carts[productInCartIndex].qty = 1
      } else {
        state.carts[productInCartIndex].qty = qty
      }
    }
  },

  SET_ORDER_TYPE: (state, { type }) => {
    state.orderPayload.type = type
  },

  SET_PAYMENT_TYPE: (state, { type }) => {
    state.orderPayload.payment_type = type
  },

  SET_DISCOUNT_TYPE: (state, { type }) => {
    state.orderPayload.discount_type = type
  },

  SET_ORDER_DEAL: (state, { type }) => {
    state.orderPayload.deal = type
  },

  SET_DISCOUNT_AMOUNT: (state, { amount }) => {
    state.orderPayload.discount = parseInt(amount)
  },

  SET_AMOUNT_TO_PAY: (state, { total }) => {
    state.amountToPay = total
  },
  SET_ORDER_NOTE: (state, { note }) => {
    state.orderPayload.note = note
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
